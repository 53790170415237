import { Box } from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useMemo } from "react";
import { fetchGet, newGuid } from "wcz-layout";
import { GridToolbar } from "wcz-x-data-grid";
import ImportEtaHistory from "../../../models/importEtaHistory";
import { etaUrl } from "../../../utils/BaseUrl";

interface HistoryTableProps {
    importNumber: string
}

export default function HistoryTable(props: HistoryTableProps) {

    const { data = [], isLoading } = useQuery<ImportEtaHistory[]>(["etaHistory", props.importNumber], ({ signal }) => fetchGet(etaUrl + "/v1/etatablehistory/" + props.importNumber, signal), {
        enabled: !!props.importNumber,
        select: data => data.map(obj => ({ ...obj, id: newGuid() }))
    });

    const columns: GridColDef[] = useMemo(() => [
        { field: "columnName", headerName: "Column name", width: 250, maxWidth: 500, },
        { field: "oldValue", headerName: "Old value", width: 250, maxWidth: 500, },
        { field: "newValue", headerName: "New value", width: 250, maxWidth: 500, },
        { field: "timeStamp", headerName: "Time", width: 180, maxWidth: 500, type: "date", valueGetter: ({ value }) => value && new Date(value), },
    ] as GridColDef[], []);

    return (
        <Box sx={{ display: "flex", height: 400 }}>
            <DataGridPremium rows={data} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading} />
        </Box>
    );
}