import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { fetchPut, LayoutContext } from "wcz-layout";
import ImportEta from "../../models/ImportEta";
import { etaUrl } from "../../utils/BaseUrl";
import DetailTable from "./etaDetail/DetailTable";
import HistoryTable from "./etaDetail/HistoryTable";

interface EtaDetailProps {
    open: boolean,
    onClose: () => void,
    eta: ImportEta,
    refetch: () => void
}

export default function EtaDetail(props: EtaDetailProps) {
    const [remark, setRemark] = useState("");
    const { t, snackbar } = useContext(LayoutContext);

    useEffect(() => {
        setRemark(props.eta.remark);

        // eslint-disable-next-line
    }, [props.eta.remark]);

    const { mutate } = useMutation((req: any) => fetchPut(etaUrl + "/v1/etatable/" + req.importNumber, req), {
        onSuccess: () => { snackbar({ message: t("Updated") }); props.refetch(); },
    });

    const updateRemark = () => {
        const requestModel = {
            importNumber: props.eta.importNumber,
            remark: remark
        };

        mutate(requestModel);
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth="lg" fullWidth>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <DialogTitle>{props.eta.importNumber}</DialogTitle>
                </Grid>

                <Grid item sx={{ p: 1 }}>
                    <IconButton onClick={props.onClose}>
                        <Close fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>

            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField label={t("Remark")} value={remark} onChange={e => setRemark(e.target.value)} fullWidth onBlur={updateRemark} size="small" />
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Typography variant="h6">Detail</Typography>
                        <DetailTable importNumber={props.eta.importNumber} />
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Typography variant="h6">{t("History")}</Typography>
                        <HistoryTable importNumber={props.eta.importNumber} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}