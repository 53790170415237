import { Box } from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { fetchGet, newGuid } from "wcz-layout";
import { GridToolbar } from "wcz-x-data-grid";
import ImportEtaDetail from "../../../models/ImportEtaDetail";
import { etaUrl } from "../../../utils/BaseUrl";

interface DetailTableProps {
    importNumber: string
}

export default function DetailTable(props: DetailTableProps) {

    const { data = [], isLoading } = useQuery<ImportEtaDetail[]>(["etaDetail", props.importNumber], ({ signal }) => fetchGet(etaUrl + "/v1/etatabledetail/" + props.importNumber, signal), {
        enabled: !!props.importNumber,
        select: data => data.map(obj => ({ ...obj, id: newGuid() }))
    });

    const columns: GridColDef[] = useMemo(() => [
        { field: "subItem", headerName: "SubItem", width: 120, maxWidth: 500, },
        { field: "purchasingDocument", headerName: "Purchasing document", width: 250, maxWidth: 500, },
        { field: "item", headerName: "Item", maxWidth: 500, },
        { field: "material", headerName: "Material", width: 200, maxWidth: 500, },
        { field: "orderedQuantity", headerName: "Ordered quantity", width: 140, maxWidth: 500, type: "number" },
        { field: "receivedQuantity", headerName: "Received quantity", width: 120, maxWidth: 500, type: "number" },
    ] as GridColDef[], []);

    return (
        <Box sx={{ display: "flex", height: 400 }}>
            <DataGridPremium rows={data} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading} />
        </Box>
    );
}