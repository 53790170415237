import { Done, FlashOnSharp } from "@mui/icons-material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import { uniqBy } from "lodash";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { LayoutContext, fetchGet } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import EtaDetail from "../components/home/EtaDetail";
import ImportEta from "../models/ImportEta";
import { etaUrl } from "../utils/BaseUrl";

export default function Home() {
    const [detail, setDetail] = useState({} as ImportEta);
    const { changeTitle } = useContext(LayoutContext);

    const { data = [], refetch, isLoading } = useQuery<ImportEta[]>(["eta"], ({ signal }) => fetchGet(etaUrl + "/v1/etatable", signal), {
        select: data => data.map(obj => ({ ...obj, id: obj.importNumber + obj.container })),
    });

    const incoterms: string[] = useMemo(() => uniqBy(data, "incoterms").map(i => i.incoterms).filter(i => i), [data]);
    const shipWays: string[] = useMemo(() => uniqBy(data, "shipWay").map(i => i.shipWay).filter(i => i), [data]);

    useEffect(() => changeTitle("ETA table"), []);

    const columns: GridColDef[] = useMemo(() => [
        {
            field: "icon", headerName: " ", width: 50, maxWidth: 500,
            filterable: false, renderCell: params => {
                const totalQuantity = Number(params.row.totalQuantity);
                const totalReceivedQuantity: number = Number(params.row.totalReceivedQuantity);

                if (totalReceivedQuantity) {
                    if (totalReceivedQuantity > 0 && totalReceivedQuantity < totalQuantity)
                        return <FlashOnSharp color="warning" />;
                    if (totalReceivedQuantity === totalQuantity)
                        return <Done color="success" />;
                }
                else {
                    return "";
                }
            }
        },
        { field: "importNumber", headerName: "Import No.", width: 120, maxWidth: 500, },
        { field: "hawb", headerName: "Hawb", width: 150, maxWidth: 500, },
        { field: "container", headerName: "Container No.", width: 130, maxWidth: 500, },
        { field: "packing", headerName: "Pack unit", width: 100, maxWidth: 500, },
        { field: "arrivalDate", headerName: "ETA port", type: "date", valueGetter: ({ value }) => value && new Date(value), },
        { field: "arrivalDateNew", headerName: "New ETA port", width: 110, maxWidth: 500, type: "date", valueGetter: ({ value }) => value && new Date(value), },
        { field: "releaseDate", headerName: "ETA WCZ-SAP", width: 120, maxWidth: 500, type: "date", valueGetter: ({ value }) => value && new Date(value), },
        { field: "releaseDateNew", headerName: "New ETA WCZ-SAP", width: 150, maxWidth: 500, type: "date", valueGetter: ({ value }) => value && new Date(value), },
        { field: "lastChange", headerName: "Updated", type: "date", maxWidth: 500, valueGetter: ({ value }) => value && new Date(value), },
        { field: "arrivalTimeEta", headerName: "Arriving time WCZ", width: 130, maxWidth: 500, },
        { field: "remark", headerName: "Remark", width: 150, maxWidth: 500, },
        { field: "shipWay", headerName: "ShipWay", width: 150, maxWidth: 500, },
        { field: "incoterms", headerName: "Inco terms", maxWidth: 500, },
        { field: "receivedDate", headerName: "Actual arriving WCZ", width: 150, maxWidth: 500, type: "date", valueGetter: ({ value }) => value && new Date(value), },
        { field: "vesselName", headerName: "Vessel", width: 130, maxWidth: 500, },
        { field: "plant", headerName: "Plant", type: "singleSelect", valueOptions: ["F6H1", "F6H2"], maxWidth: 500, },
        { field: "vendorName", headerName: "Vendor", width: 250, maxWidth: 500, },
        { field: "createdDate", headerName: "Created", type: "date", maxWidth: 500, valueGetter: ({ value }) => value && new Date(value), },
        { field: "totalQuantity", headerName: "Qty", type: "number", maxWidth: 500, },
        { field: "totalReceivedQuantity", headerName: "Received quantity", type: "number", maxWidth: 500, },
    ] as GridColDef[], [incoterms, shipWays]);

    return (
        <Fragment>
            <TableContainer>
                <DataGridPremium rows={data} columns={columns} loading={isLoading} onCellDoubleClick={params => setDetail(params.row as ImportEta)}
                    slots={{ toolbar: GridToolbar }} slotProps={{ toolbar: { viewKey: "eta" } as GridToolbarProps }}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: "createdDate",
                                    sort: "desc",
                                },
                            ],
                        }
                    }}
                />
            </TableContainer>

            <EtaDetail open={!!detail.importNumber} onClose={() => setDetail({} as ImportEta)} eta={detail} refetch={refetch} />
        </Fragment>
    );
}