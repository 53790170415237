import { Home } from "@mui/icons-material";
import { red, purple } from '@mui/material/colors';
import { useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LayoutProvider, LeftDrawerItem, useUser } from "wcz-layout";
import packageJson from "../package.json";
import Homepage from "./pages/Home";

export const App: React.FC = () => {
    const user = useUser();

    const leftDrawerItems: LeftDrawerItem[] = useMemo(() => [
        { title: "Layout.Home", path: "/", icon: <Home fontSize="large" /> },
    ], [user]);

    return (
        <BrowserRouter>
            <LayoutProvider title={packageJson.name} appVersion={packageJson.version} primaryColor={purple[600]} secondaryColor={red[500]} leftDrawerItems={leftDrawerItems}>
                <Routes>
                    <Route path='/' element={<Homepage />} />
                </Routes>
            </LayoutProvider>
        </BrowserRouter>
    );
};